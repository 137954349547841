type ENV_NAME = 'test' | 'development' | 'staging' | 'production';

const AWS_S3_PUBLIC_BUCKET_NAMES: Record<ENV_NAME, string> = {
  test: 'appstoragepublicdev',
  development: 'appstoragepublicdev',
  staging: 'appstoragepublicstaging',
  production: 'appstoragepublicprod',
};

export const getS3BucketName = () => {
  const envName = process.env.ENV_NAME as ENV_NAME;
  if (!envName) {
    throw new Error('ENV_NAME is not set');
  }

  const bucketName = AWS_S3_PUBLIC_BUCKET_NAMES[envName];
  if (!bucketName) {
    throw new Error(`Invalid ENV_NAME: ${envName}`);
  }

  return bucketName;
};
