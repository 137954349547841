'use client';

import { Box, useTheme } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { Variant } from '@mui/material/styles/createTypography';
type PreStyledRichTextFieldProps = {
  htmlContent: string;
};
export const PreStyledRichTextField = ({
  htmlContent
}: PreStyledRichTextFieldProps) => {
  const theme = useTheme();
  const typographyStyles = Object.keys(theme.typography).reduce<Record<string, object>>((acc, key) => {
    const value = theme.typography[(key as Variant)];
    return typeof value === 'object' ? {
      ...acc,
      [key]: value
    } : acc;
  }, {});
  return <Box sx={{
    /**
     * Keep synced with useTinyMceConfig.tsx
     */
    ...typographyStyles,
    'h1,h2,h3': {
      m: 0,
      mb: 3
    },
    'h4,h5,h6,p': {
      m: 0,
      mb: 2
    },
    '& p': typographyStyles.paragraph
  }} dangerouslySetInnerHTML={{
    __html: htmlContent
  }} data-sentry-element="Box" data-sentry-component="PreStyledRichTextField" data-sentry-source-file="PreStyledRichTextField.tsx" />;
};