'use client';

import { PropsWithChildren } from 'react';
import { Box, Stack } from '@mui/material';
import { useIsOverBreakpoint, useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { BaseCarousel } from '@/components/Common/BaseCarousel/BaseCarousel';
export const SixCategoriesRowCarousel = ({
  children
}: PropsWithChildren) => {
  const isDesktop = useIsOverBreakpoint('xl');
  const isMobile = useIsUnderBreakpoint('sm');
  return <Box sx={{
    mt: 3
  }} data-sentry-element="Box" data-sentry-component="SixCategoriesRowCarousel" data-sentry-source-file="SixCategoriesRowCarousel.tsx">
      {isMobile || isDesktop ? <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
          {children}
        </Stack> : <BaseCarousel slidesCount={6} slidesToShow={{
      xs: 6,
      sm: 3,
      md: 4,
      lg: 5,
      xl: 6
    }}>
          {children}
        </BaseCarousel>}
    </Box>;
};